import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { combineLatest, Observable, of } from 'rxjs';
import { tap, map, finalize, startWith } from 'rxjs/operators';

import { BaseFormComponent, FormAction } from '../../../shared/components/base/base-form.component';

import {
  LocationDto,
  LocationWmsService,
  ProductLocationDto,
  ProductLocationWmsService,
} from 'src/app/services/wms.service';

import { ProductDto, ProductsService } from 'src/app/services/products.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-product-location-form',
  templateUrl: './product-location-form.component.html'
})


export class ProductLocationFormComponent extends BaseFormComponent<ProductLocationDto> implements OnInit
{
  isLoading: boolean;
  currentStore: string;

  locations$: Observable<LocationDto[]>;
  filteredLocations: Observable<LocationDto[]>;

  selectedLocation: LocationDto;
  selectedProduct: ProductDto;

  locationControl = new FormControl();

  

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(  private productLocationService: ProductLocationWmsService,
                private locationService: LocationWmsService,
                public dialogRef: MatDialogRef<ProductLocationDto>,
                private productService: ProductsService,
                @Inject(MAT_DIALOG_DATA) public data: any) 
  {
    
    super(dialogRef, data);

    this.model = new ProductLocationDto();
  }

  ngOnInit(): void {

    //setTimeout(() => this.cancelButton.nativeElement.focus());

    const sku = this.data.sku;
    const countryCode = this.data.countryCode;
    const locationId  = this.data.locationId;

    this.currentStore = this.data.storeCode;

    this.productService.getBySku(sku, countryCode)
                       .subscribe((resp: any) => this.selectedProduct = resp);

    this.isLoading   = true;
    this.locations$  = this.locationService
                           .getAll(countryCode, 'id asc', 0, 10000)
                           .pipe( map( x => x.items),
                                  tap(items => { 
                                    if (locationId) {
                                      this.selectedLocation = items.find(x => x.id === locationId);
                                      this.locationControl.setValue(this.selectedLocation);
                                    }
                                  }),
                                  finalize(() => this.isLoading = false));

    this.filteredLocations = combineLatest([
      this.locations$,
      this.locationControl.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([locations, value]) => this._filter(locations, typeof value === 'string' ? value : value?.code || ''))
    );
    
  }; 

  ngAfterViewInit(): void {
    this.cancelButton.nativeElement?.focus();
  }


  private _filter(locations: LocationDto[], value: string): LocationDto[] {
    const filterValue = value ? value.toLowerCase() : '';
    return locations.filter(location => location.code.toLowerCase().includes(filterValue));
  }


  locationChange(event) {
    console.log('locationChange', event.option.value)
    this.selectedLocation = event.option.value;
  }

  displayLocationCode(location: LocationDto): string {
    return location ? location.code : '';
  }

  enableSendBtn(){
    return ( this.selectedLocation );
  }

  getModel(): Observable<any> {
    throw new Error('Method not implemented.');
  }




  send() {

    this.model.storeCode    = this.currentStore;
    this.model.locationId   = this.selectedLocation.id;
    this.model.locationCode = this.selectedLocation.code;
    this.model.productId    = this.selectedProduct.id;
    this.model.productName  = this.selectedProduct.name;
    this.model.sku          = this.selectedProduct.sku;
    this.model.ean          = this.selectedProduct.barCode;
    this.model.imageUrl     = null;

    //console.log('send', this.model);

   // crear / actulizar el wms... 
    this.isLoading = true;
    var obs = (this.action == FormAction.create) ? this.create() : this.edit();

    obs.pipe(finalize(() => this.isLoading = false))
       .subscribe(result => {
        // paso el dto actualizado a la pantalla de picking y cierro modal
        this.dialogRef.close(result);
    });

  }

  create(): Observable<any> {
    return this.productLocationService.create(this.model);
  }

  edit(): Observable<any> {
    return this.productLocationService.update(this.model);
  }

}
