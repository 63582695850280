import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrdersListComponent } from '../oms/orders/orders-list/orders-list.component';
import { ServiceProxyModule } from '../oms/services/service-proxy/service-proxy.module';
import { SharedModule } from '../shared/shared.module';
import { API_BASE_URL } from '../oms/services/service-proxy/service-proxies';
import { OmsRoutingModule } from './oms-routing.module';
import { CarriersListComponent } from './carriers/carriers-list/carriers-list.component';
import { CarrierFormComponent } from './carriers/carrier-form/carrier-form.component';
import { OrderSetCarrierFormComponent } from './orders/order-set-carrier-form/order-set-carrier-form.component';
import { OrderSetStatusFormComponent } from './orders/order-set-status-form/order-set-status-form.component';
import { OrderSetStoreFormComponent } from './orders/order-set-store-form/order-set-store-form.component';
import { OrderSetRejectFormComponent } from './orders/order-set-reject-form/order-set-reject-form.component';
import { OrderNoteFormComponent } from './orders/order-notes-form/order-notes-form.component';
import { OrdersReadyToShipListComponent } from './orders/orders-ready-to-ship-list/orders-ready-to-ship-list.component';
import { OrderDispatchFormComponent } from './orders/order-dispatch-form/order-dispatch-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from '../shared/interceptors/authentication-interceptor';
import { OrderManualFormComponent } from './orders/order-manual-form/order-manual-form.component';
import { DocumentTypeSelectComponent } from './components/document-type-select/document-type-select.component';
import { OrderHeaderFormComponent } from './orders/order-header-form/order-header-form.component';
import { CarrierSelectComponent } from './components/carrier-select/carrier-select.component';
import { OrdersReadyToHandleListComponent } from './orders/orders-ready-to-handle-list/orders-ready-to-handle-list.component';
import { OrderSeparationFormComponent } from './orders/order-separation-form/order-separation-form.component';
import { OrderResponsibleFormComponent } from './orders/order-responsible-form/order-responsible-form.component';
import { OrderSeparationListComponent } from './orders/order-separation-list/order-separation-list.component';
import { OrderPreparationFormComponent } from './orders/order-preparation-form/order-preparation-form.component';
import { ProductsService } from '../services/products.service';
import { OrderSeparationManageFormComponent } from './orders/order-separation-manage-form/order-separation-manage-form.component';
import { OrdersReadyToHandleListModalComponent } from './orders/orders-ready-to-handle-list-modal/orders-ready-to-handle-list-modal.component';
import { OrderSeparationCreateFormComponent } from './orders/order-separation-create-form/order-separation-create-form.component';
import { OrderSeparationClipboardFormComponent } from './orders/order-separation-clipboard-form/order-separation-clipboard-form.component';
import { ShippingTypesListComponent } from './shipping-types/shipping-types-list/shipping-types-list.component';
import { ShippingTypeFormComponent } from './shipping-types/shipping-type-form/shipping-type-form.component';
import { OrdersReadyToDispatchListComponent } from './orders/orders-ready-to-dispatch-list/orders-ready-to-dispatch-list.component';
import { OrderDispatchCreateFormComponent } from './orders/order-dispatch-create-form/order-dispatch-create-form.component';
import { ShipmentsListComponent } from './shipments/shipments-list/shipments-list.component';
import { ShipmentFormComponent } from './shipments/shipment-form/shipment-form.component';
import { StoreAsignationFormComponent } from './orders/store-asignation-form/store-asignation-form.component';
import { ShippingTypeMappingsListComponent } from './shipping-types/shipping-type-mappings-list/shipping-type-mappings-list.component';
import { ShippingTypeMappingFormComponent } from './shipping-types/shipping-type-mapping-form/shipping-type-mapping-form.component';
import { CarrierDriverFormComponent } from './carrier-drivers/carrier-driver-form/carrier-driver-form.component';
import { CarrierDriversListComponent } from './carrier-drivers/carrier-drivers-list/carrier-drivers-list.component';
import { ShipmentDetailComponent } from './shipments/shipment-detail/shipment-detail.component';
import { PromotionListComponent } from './promotions/promotion-list/promotion-list.component';
import { PromotionFormComponent } from './promotions/promotion-form/promotion-form.component';
import { FiltersService } from './services/filters.service';
import { OrdersInvoicedComponent } from './orders/orders-invoiced/orders-invoiced.component';
import { CustomValuesListComponent } from './custom-values/custom-values-list/custom-values-list.component';
import { CustomValueFormComponent } from './custom-values/custom-value-form/custom-value-form.component';
import { DeliveryRegistrationComponent } from './shipments/delivery-registration/delivery-registration.component';
import { CustomValueSelectComponent } from './components/custom-value-select/custom-value-select.component';
import { ShippingTypeGroupListComponent } from './shipping-type-groups/shipping-type-group-list/shipping-type-group-list.component';
import { ShippingTypeGroupFormComponent } from './shipping-type-groups/shipping-type-group-form/shipping-type-group-form.component';
import { CreateClaimFormComponent } from './claims/create-claim-form/create-claim-form.component';
import { ClaimTypeSelectComponent } from './components/claim-type-select/claim-type-select.component';
import { OrderClaimActionSelectComponent } from './components/order-claim-action-select/order-claim-action-select.component';
import { SkuClaimActionSelectComponent } from './components/sku-claim-action-select/sku-claim-action-select.component';
import { ClaimsListComponent } from './claims/claims-list/claims-list.component';
import { OrdersRegistrationComponent } from './shipments/orders-registration/orders-registration.component';
import { AffectedOrdersComponent } from './picking-groups/affected-orders/affected-orders.component';
import { InfoContainerComponent } from '../landing/info/info-container/info-container.component';
import { InfoCardMailComponent } from '../landing/info/info-card-mail/info-card-mail.component';
import { OrdersLogisticAuditListComponent } from './orders/orders-logistic-audit-list/orders-logistic-audit-list.component';
import { OrderNotesClipboardFormComponent } from './orders/order-notes-clipboard-form/order-notes-clipboard-form.component';
import { UploadRouteComponent } from './orders/orders-ready-to-dispatch-list/upload-route/upload-route.component';
import { S3Service, S3_BUCKET } from '../services/s3.service';
import { OrderItemsEditFormComponent } from './orders/order-items-edit-form/order-items-edit-form.component';
import { PendingManagementComponent } from './orders/pending-management/pending-management.component';
import { BalanceListComponent } from './orders/pending-management/balance-list/balance-list.component';
import { AmountComponent } from './orders/pending-management/amount/amount.component';
import { UploadPromotionsComponent } from './promotions/upload-promotions/upload-promotions.component';
import { ClaimItemsFormComponent } from './claims/claim-items-form/claim-items-form.component';
import { ReturnListComponent } from './returns/return-list/return-list.component';
import { ReturnItemsFormComponent } from './returns/return-items-form/return-items-form.component';
import { ReturnFormComponent } from './returns/return-form/return-form.component';
import { StorePostalCodesComponent } from './store-postal-codes/store-postal-codes.component';
import { ImportPostalCodesComponent } from './store-postal-codes/import-postal-codes/import-postal-codes.component';
import { RequestToStoreFormComponent } from './picking-groups/request-to-store-form/request-to-store-form.component';
import { MappingDeliveryTimesComponent } from './mapping-delivery-types/mapping-delivery-times.component';
import { MappingDeliveryTimesFormComponent } from './mapping-delivery-types/mapping-delivery-types-form/mapping-delivery-times-form.component';
import { OrderSetDraftFormComponent } from './orders/order-set-draft-form/order-set-draft-form.component';
import { TransfersService } from '../services/transfers.service';
import { QuadmindsPreviewComponent } from './orders/quadminds-preview/quadminds-preview.component';
import { OrderDetailFormComponent } from './orders/detail/order-detail-form/order-detail-form.component';
import { OrderHeaderTabComponent } from './orders/detail/order-header-tab/order-header-tab.component';
import { OrderDetailTabComponent } from './orders/detail/order-detail-tab/order-detail-tab.component';
import { OrderLogisticTabComponent } from './orders/detail/order-logistic-tab/order-logistic-tab.component';
import { OrderHistoryTabComponent } from './orders/detail/order-history-tab/order-history-tab.component';
import { OrderClaimTabComponent } from './orders/detail/order-claim-tab/order-claim-tab.component';
import { OrderInvoiceTabComponent } from './orders/detail/order-invoice-tab/order-invoice-tab.component';
import { OrdersStorePickupComponent } from './orders/orders-store-pickup/orders-store-pickup.component';
import { AgmCoreModule } from '@agm/core';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { OrderPickupNoteFormComponent } from './orders/order-pickup-note-form/order-pickup-note-form.component';
import { QuadmindsClipboardComponent } from './orders/quadminds-preview/quadminds-clipboard.component';
import { UpdatePickupStoreFormComponent } from './orders/update-pickup-store-form/update-pickup-store-form.component';
import { OrderNotesTabComponent } from './orders/detail/order-notes-tab/order-notes-tab.component';
import { OrderItemsPromoFormComponent } from './orders/order-items-promo-form/order-items-promo-form.component';
import { DateTagPickerComponent } from './components/date-tag-picker/date-tag-picker.component';
import { TransferItemsFormComponent } from './orders/detail/order-logistic-tab/transfer-items-form/transfer-items-form.component';
import { OrderDetailAddressComplementComponent } from './orders/order-detail-address-complement/order-detail-address-complement.component';
import { RappiListComponent } from './rappi/rappi-list/rappi-list.component';
import { RappiFormComponent } from './rappi/rappi-form/rappi-form.component';
import { VtexAccountsListComponent } from './vtex-accounts/vtex-accounts-list.component';
import { VtexAccountFormComponent } from './vtex-accounts/vtex-account-form/vtex-account-form.component';
import { RappiConfigListComponent } from './rappi/rappi-config-list/rappi-config-list.component';
import { RappiConfigFormComponent } from './rappi/rappi-config-form/rappi-config-form.component';
import { ConflictItemsFormComponent } from './orders/orders-logistic-audit-list/conflict-items-form/conflict-items-form.component';
import { UploadRappiDiscountsComponent } from './rappi/upload-rappi-discounts/upload-rappi-discounts.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { FlyersListComponent } from './flyers/flyers-list.component';
import { FlyerFormComponent } from './flyers/flyer-form/flyer-form.component';
import { FlyersUploadComponent } from './flyers/flyers-upload/flyers-upload.component';
import { BinsListComponent } from './bins/bins-list.component';
import { BinFormComponent } from './bins/bin-form/bin-form.component';
import { UploadBinComponent } from './bins/upload-bin/upload-bin.component';
import { SpreadProductsFormComponent } from './orders/order-separation-form/spread-products-form/spread-products-form.component';
import { SpreadOrdersComponent } from './orders/order-separation-form/spread-orders/spread-orders.component';
import { FlyerAbmListComponent } from './flyers-abm/flyers-abm-list/flyers-abm-list.component';
import { FlyerAbmFormComponent } from './flyers-abm/flyers-abm-form/flyers-abm-form.component';
import { PickingGroupsRulesListComponent } from './picking-groups-rules/picking-groups-rules-list.component';
import { PickingGroupsRuleFormComponent } from './picking-groups-rules/picking-groups-rule-form/picking-groups-rule-form.component';
import { PickingGroupsRuleUploadComponent } from './picking-groups-rules/picking-groups-rule-upload/picking-groups-rule-upload.component';
import { MappingDeliveryTimesUploadComponent } from './mapping-delivery-types/mapping-delivery-times-upload/mapping-delivery-times-upload.component';
import { ViewPostalCodesDialogComponent } from './picking-groups-rules/picking-groups-modal-cp/picking-groups-modal-cp.component';
import { PendingReturnListComponent } from './returns/pending-return-list/pending-return-list.component';
import { OrdersLogisticAuditRequestTransferComponent } from './orders/orders-logistic-audit-request-transfer/orders-logistic-audit-request-transfer.component';
import { GeneralAuditListComponent } from './general-audit/general-audit-list/general-audit-list.component';
import { LogisticDetailComponent } from './general-audit/logistic-detail/logistic-detail.component';
import { ReturnedOrdersFormComponent } from './shipments/returned-orders-form/returned-orders-form.component';
import { FailedDeliveryDetailComponent } from './general-audit/failed-delivery-detail/failed-delivery-detail.component';
import { DeliveryScheduleListComponent } from './delivery-schedule/delivery-schedule-list/delivery-schedule-list.component';
import { DeliveryScheduleFormComponent } from './delivery-schedule/delivery-schedule-form/delivery-schedule-form.component';
import { AddDeliveryScheduleFormComponent } from './shipping-types/add-delivery-schedule-form/add-delivery-schedule-form.component';
import { StatisticsComponent } from './zoho/statistics/statistics.component';
import { StatisticsFormComponent } from './zoho/statistics-form/statistics-form.component';
import { RappiImportComponent } from './rappi/rappi-import/rappi-import.component';
import { SingleCustomerComponent } from './orders/order-manual-form/single-customer/single-customer.component';
import { SingleCustomerFormComponent } from './orders/order-manual-form/single-customer-form/single-customer-form.component';
import { DeliveryDataComponent } from './orders/order-manual-form/delivery-data/delivery-data.component';
import { OrderReturnedComponent } from './orders/order-returned/orders-returned-list.component';
import { OrderNotificationTabComponent } from './orders/detail/order-notification-tab/order-notification-tab.component';
import { ShippingAdminModule } from '../mailling/shipping-admin/shipping-admin.module';
import { PendingReturnFormComponent } from './returns/pending-return-form/pending-return-form.component';
import { MeliNotificationComponent } from './meli-notifications/meli-notification.component';
import { OrderShipmentsReportComponent } from './orders/order-shipments-report/order-shipments-report.component';

@NgModule({
  declarations: [
    CarrierFormComponent,
    CarriersListComponent,
    CarrierDriverFormComponent,
    CarrierDriversListComponent,
    DocumentTypeSelectComponent,
    OrderManualFormComponent,
    OrderDispatchFormComponent,
    OrderHeaderFormComponent,
    OrderNoteFormComponent,
    OrderSetCarrierFormComponent,
    OrderDetailComponent,
    OrderSetRejectFormComponent,
    OrderSetStatusFormComponent,
    OrderSetStoreFormComponent,
    OrdersListComponent,
    OrdersReadyToHandleListComponent,
    OrdersReadyToShipListComponent,
    OrderSeparationFormComponent,
    OrderResponsibleFormComponent,
    OrderSeparationListComponent,
    OrderPreparationFormComponent,
    OrderSeparationManageFormComponent,
    OrdersReadyToHandleListModalComponent,
    CarrierSelectComponent,
    OrderSeparationCreateFormComponent,
    OrderSeparationClipboardFormComponent,
    ShippingTypesListComponent,
    ShippingTypeFormComponent,
    OrdersReadyToDispatchListComponent,
    OrderDispatchCreateFormComponent,
    ShipmentsListComponent,
    ShipmentFormComponent,
    StoreAsignationFormComponent,
    ShippingTypeMappingsListComponent,
    ShippingTypeMappingFormComponent,
    ShipmentDetailComponent,
    PromotionListComponent,
    PromotionFormComponent,
    OrdersInvoicedComponent,
    CustomValuesListComponent,
    CustomValueFormComponent,
    DeliveryRegistrationComponent,
    CustomValueSelectComponent,
    CreateClaimFormComponent,
    ClaimTypeSelectComponent,
    OrderClaimActionSelectComponent,
    SkuClaimActionSelectComponent,
    ClaimsListComponent,
    OrdersRegistrationComponent,
    AffectedOrdersComponent,
    InfoContainerComponent,
    InfoCardMailComponent,
    OrdersLogisticAuditListComponent,
    OrderNotesClipboardFormComponent,
    UploadRouteComponent,
    OrderItemsEditFormComponent,
    ShippingTypeGroupListComponent,
    ShippingTypeGroupFormComponent,
    PendingManagementComponent,
    BalanceListComponent,
    AmountComponent,
    UploadPromotionsComponent,
    ClaimItemsFormComponent,
    ReturnListComponent,
    ReturnItemsFormComponent,
    ReturnFormComponent,
    StorePostalCodesComponent,
    ImportPostalCodesComponent,
    RequestToStoreFormComponent,
    MappingDeliveryTimesComponent,
    MappingDeliveryTimesFormComponent,
    OrderSetDraftFormComponent,
    QuadmindsPreviewComponent,
    OrderDetailFormComponent,
    OrderHeaderTabComponent,
    OrderDetailTabComponent,
    OrderLogisticTabComponent,
    OrderHistoryTabComponent,
    OrderNotificationTabComponent,
    OrderClaimTabComponent,
    OrderInvoiceTabComponent,
    OrdersStorePickupComponent,
    OrderPickupNoteFormComponent,
    QuadmindsClipboardComponent,
    DateTagPickerComponent,
    UpdatePickupStoreFormComponent,
    OrderNotesTabComponent,
    OrderItemsPromoFormComponent,
    TransferItemsFormComponent,
    OrderDetailAddressComplementComponent,
    RappiListComponent,
    RappiFormComponent,
    VtexAccountsListComponent,
    VtexAccountFormComponent,
    RappiConfigListComponent,
    RappiConfigFormComponent,
    ConflictItemsFormComponent,
    UploadRappiDiscountsComponent,
    FlyersListComponent,
    FlyerFormComponent,
    FlyersUploadComponent,
    BinsListComponent,
    BinFormComponent,
    UploadBinComponent,
    SpreadProductsFormComponent,
    SpreadOrdersComponent,
    FlyerAbmFormComponent,
    FlyerAbmListComponent,
    PickingGroupsRulesListComponent,
    PickingGroupsRuleFormComponent,
    PickingGroupsRuleUploadComponent,
    MappingDeliveryTimesUploadComponent,
    ViewPostalCodesDialogComponent,
    PendingReturnListComponent,
    OrdersLogisticAuditRequestTransferComponent,
    GeneralAuditListComponent,
    LogisticDetailComponent,
    ReturnedOrdersFormComponent,
    FailedDeliveryDetailComponent,
    DeliveryScheduleListComponent,
    DeliveryScheduleFormComponent,
    AddDeliveryScheduleFormComponent,
    StatisticsComponent,
    StatisticsFormComponent,
    RappiImportComponent,
    SingleCustomerComponent,
    SingleCustomerFormComponent,
    DeliveryDataComponent,
    OrderReturnedComponent,
    PendingReturnFormComponent,
    MeliNotificationComponent,
    OrderShipmentsReportComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule,
    VerticalTimelineModule,
    SharedModule,
    ServiceProxyModule,
    OmsRoutingModule,
    ShippingAdminModule,
  ],
  exports: [
    ServiceProxyModule,
  ],
  entryComponents: [
    CarrierFormComponent,
    CarrierDriverFormComponent,
    OrderManualFormComponent,
    OrderDispatchFormComponent,
    OrderNoteFormComponent,
    OrderHeaderFormComponent,
    OrderSetCarrierFormComponent,
    OrderSetStatusFormComponent,
    OrderSetStoreFormComponent,
    OrderSetRejectFormComponent,
    OrderSeparationFormComponent,
    OrderResponsibleFormComponent,
    OrderPreparationFormComponent,
    OrderSeparationManageFormComponent,
    OrdersReadyToHandleListModalComponent,
    CarrierSelectComponent,
    OrderSeparationCreateFormComponent,
    OrderSeparationClipboardFormComponent,
    ShippingTypeFormComponent,
    OrderDispatchCreateFormComponent,
    ShipmentFormComponent,
    StoreAsignationFormComponent,
    ShippingTypeMappingsListComponent,
    ShippingTypeMappingFormComponent,
    ShipmentDetailComponent,
    PromotionFormComponent,
    CustomValueFormComponent,
    DeliveryRegistrationComponent,
    OrdersRegistrationComponent,
    CreateClaimFormComponent,
    AffectedOrdersComponent,
    InfoContainerComponent,
    InfoCardMailComponent,
    OrderNotesClipboardFormComponent,
    UploadRouteComponent,
    OrderItemsEditFormComponent,
    BalanceListComponent,
    ShippingTypeGroupFormComponent,
    AmountComponent,
    UploadPromotionsComponent,
    ClaimItemsFormComponent,
    ReturnItemsFormComponent,
    ReturnFormComponent,
    ImportPostalCodesComponent,
    RequestToStoreFormComponent,
    MappingDeliveryTimesFormComponent,
    OrderSetDraftFormComponent,
    QuadmindsPreviewComponent,
    OrderDetailFormComponent,
    OrderPickupNoteFormComponent,
    QuadmindsClipboardComponent,
    UpdatePickupStoreFormComponent,
    OrderItemsPromoFormComponent,
    TransferItemsFormComponent,
    OrderDetailAddressComplementComponent,
    RappiFormComponent,
    VtexAccountFormComponent,
    RappiConfigListComponent,
    RappiConfigFormComponent,
    ConflictItemsFormComponent,
    UploadRappiDiscountsComponent,
    FlyerFormComponent,
    FlyersUploadComponent,
    BinFormComponent,
    UploadBinComponent,
    SpreadProductsFormComponent,
    SpreadOrdersComponent,
    FlyerAbmFormComponent,
    PickingGroupsRuleFormComponent,
    PickingGroupsRuleUploadComponent,
    MappingDeliveryTimesUploadComponent,
    ViewPostalCodesDialogComponent,
    OrdersLogisticAuditRequestTransferComponent,
    LogisticDetailComponent,
    ReturnedOrdersFormComponent,
    FailedDeliveryDetailComponent,
    DeliveryScheduleFormComponent,
    AddDeliveryScheduleFormComponent,
    StatisticsFormComponent,
    RappiImportComponent,
    SingleCustomerFormComponent,
    PendingReturnFormComponent,
  ],
  providers: [
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    { provide: S3_BUCKET, useValue: 'puppis-oms' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    ProductsService,
    TransfersService,
    FiltersService,
    S3Service,
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OmsModule {}

export function getRemoteServiceBaseUrl(): string {
  return environment.omsUrl;
}
