<div class="confirmation-dialog">
  <h3 mat-dialog-title>{{title}}</h3>
  <mat-dialog-content>
    {{text}}
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button class="btn btn__secondary btn-cancel" (click)="onCloseCancel()" *ngIf="!hideCancelButton">{{btnSecondaryText}}</button>
    <button mat-button class="btn btn__primary" (click)="onCloseConfirm()">{{btnPrimaryText}}</button>
  </mat-dialog-actions>
</div>